.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 30px;
  margin-top: 50px;
}
.logo-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-text {
  background: #ceecff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 80px;
  height: 20px;
  font: normal normal normal 12px/20px Poppins;
  letter-spacing: 0px;
  color: #0088dd;
  text-align: center;
  margin: auto;
  margin-left: 10px;
}
.more-experiements {
  width: 200px;
  height: 45px;
  border: 2px solid #a20192;
  border-radius: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #a20192;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.footer-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}
.footer-text {
  width: 270px;
  height: 31px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #a20192;
}
.content-main {
  background: #ffefff 0% 0% no-repeat padding-box;
  border-radius: 52px;
  min-height: 850px;
  margin: 20px 120px;
}
.content-title {
  text-align: center;
  font-size: 30px;
  letter-spacing: 0px;
  color: #a20192;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 100px;
  font-weight: bold;
}
.content-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #a20192;
  border-radius: 19px;
  min-height: 162px;
  margin-bottom: 5px !important;
  width: -webkit-fill-available;
  margin: 0px 100px;
  padding: 15px;
  outline: none;
  letter-spacing: 0px;
  color: #A20192;
  font: normal normal normal 20px/28px Poppins;
}
.content-validation {
  font-size: 15px;
  color: red;
  margin-bottom: 20px;
  margin: 0px 100px
}
.contentBtn-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 50px;
}
.contentBtn {
  background: #a20192 0% 0% no-repeat padding-box;
  border-radius: 19px;
  height: 55px;
  text-align: center;
  font-size: 19px;
  color: white;
  margin-bottom: 50px;
  padding: 0px 20px;
}
.experiment-desc {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 19px;
  margin: 0px 60px;
  margin-bottom: 30px;
  padding: 20px;
}
.experiment-desc-title {
  color: #7c0070;
  letter-spacing: 0px;
  margin-bottom: 25px;
  font-size: 26px;
  font-weight: bold;
}
.experiment-desc-content {
  color: #7c0070;
  letter-spacing: 0px;
  margin-bottom: 19px;
  font-size: 22px;
}
.experiment-desc-link {
  font-weight: bold;
  text-decoration: none;
  color: #7c0070;
  letter-spacing: 0px;
  margin-bottom: 25px;
  font-size: 22px;
}
.logo-btn {
  display: none;
}
.conversation-title {
  font-size: 22px;
  letter-spacing: 0px;
  color: #a20192;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 50px;
}
.conversation-main {
  background: #ffefff 0% 0% no-repeat padding-box;
  border-radius: 52px;
  margin: 20px 120px;
  width: 85%;
}
.contentBtn-title {
  text-align: left;
  color: #a20192;
  letter-spacing: 0px;
  font-size: 18px;
}
.conversationBtn-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 70px;
}
.conversationBtn {
  background: #a20192 0% 0% no-repeat padding-box;
  border-radius: 19px;
  height: 55px;
  text-align: center;
  font-size: 18px;
  color: white;
  padding: 0px 20px;
}
.conversation-gpt {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 19px;
  padding: 30px;
  margin: 30px;
}
.conversation-gpt-header {
  display: flex;
  align-items: center;
}
.conversation-gpt-title {
  font-size: 22px;
  color: #7c0070;
  letter-spacing: 0px;
  font-weight: bold;
  margin-left: 10px;
}
.conversation-content {
  height: 600px;
  overflow: auto;
}
.conversation-gpt-content {
  font: normal normal normal 20px/28px Poppins;
  letter-spacing: 0px;
  color: #7c0070;
  overflow: hidden;
  margin: 0 auto;
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}
.conversation-content::-webkit-scrollbar {
  width: 10px;
}
.conversation-content::-webkit-scrollbar-track {
  background-color: transparent;
}
.conversation-content::-webkit-scrollbar-thumb {
  background-color: #A2019240;
  background: #A2019240 0% 0% no-repeat padding-box;
  border-radius: 19px;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}
.logo-image {
  max-width: 200px;
}


@media only screen and (max-width: 1200px) {
  .content-main {
    margin: 20px 70px;
  }
}

@media only screen and (max-width: 700px) {
  .more-experiements {
    display: none;
  }
  .header-main {
    margin-bottom: 15px;
    margin-top: 25px;
  }
  .logo-btn {
    display: block;
  }
  .content-main {
    border-radius: 0px;
    margin: 0px;
  }
  .content-input {
    margin: 0px 40px;
  }
  .content-title {
    font-size: 23px;
  }
  .contentBtn {
    font-size: 16px;
    margin-bottom: 50px;
  }
  .experiment-desc-title {
    font-size: 21px;
    margin-bottom: 15px;
  }
  .experiment-desc-content, .experiment-desc-link {
    font-size: 17px;
  }
  .footer-main {
    margin: 15px 0px;
  }
  .footer-text {
    font-size: 16px;
  }
  .experiment-desc {
    margin: 0px 40px;
    margin-bottom: 20px;
  }
  .conversation-main {
    border-radius: 0px;
    margin: 0px;
    width: 100%;
  }
  .content-validation {
    margin: 0px 40px;
  }
}
